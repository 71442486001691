.welcome {
    padding: 20%;
    text-align: center;
    color: #ffffff;
}

.default-link a:link,
a:visited,
a:hover,
a:active {
    text-decoration: none;
    color: black;
}

.avatar {
    display: inline-block;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}