header {
    height: 100%;
    background: url(../../assets/images/cover_1536_x_823.webp) no-repeat fixed;
    background-size: cover;
}

.social-media img {
    padding: 5px;
    width: 32px;
    height: 32px;
}

.social-media li {
    display: inline-block;
    padding: 2px;
}