.content-section.blog {
    height: 100%;
    border-top:1px solid #efefef;
    border-bottom:1px solid #cfcfcf;
    background-color: #fafafa;
}

.content-section.blog ul{
    list-style-type: none;
}

.content-section.blog a:link a:visited a:hover a:active{
    color: black;
}